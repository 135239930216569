@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    font-family: 'Arvo', Arial, Verdana;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: inherit;
}


ul {
    list-style: none;
}

/* .carousel.carousel-slider .control-next {
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_next1.png);
    background-repeat: no-repeat;
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: top left;
    position: absolute;
    top: 50%;
    left: auto;
    opacity: 1;
} */
.carousel.carousel-slider .control-next {
    background-image: url(../src/app/assets/media/arrows/carouselArrow.svg);
    background-repeat: no-repeat;
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    left: auto;
    right: 40px;
    opacity: 1;
    background-color: #E6E5E6;
    border: 2px solid #111111;
    transform: rotate(180deg);
}

.carousel.carousel-slider .control-arrow:hover {
    background: #f0f0f0;
    background-image: url(../src/app/assets/media/arrows/carouselArrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    border: 2px solid #111111;
}

.carousel.carousel-slider .control-next:before,
.carousel.carousel-slider .control-prev:before {
    content: none;
}

.carousel.carousel-slider .control-next:hover {
    /* background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_next2.png) !important; */
    background-image: url(../src/app/assets/media/arrows/carouselArrow.svg) !important;
}

.carousel.carousel-slider .control-prev {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_prev1.png);
    top: 50%;
    bottom: auto;
    left: 0;
    right: auto;
    z-index: 999;
    position: absolute;
    opacity: 1;
}

.carousel.carousel-slider .control-prev {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    /* background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_prev1.png); */
    background-image: url(../src/app/assets/media/arrows/carouselArrow.svg);
    top: 50%;
    bottom: auto;
    /* left: 0; */
    left: 40px;
    right: auto;
    z-index: 999;
    position: absolute;
    background-position: center center;
    opacity: 1;
    background-color: #E6E5E6;
    border: 2px solid #111111;
}

.carousel.carousel-slider .control-prev:hover {
    /* background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_prev2.png) !important; */
    background-image: url(../src/app/assets/media/arrows/carouselArrow.svg) !important;
}