.app__navbar {
    max-width: 1280px;
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    /* height: 60px; */
    margin: 0 auto;
    padding: 14px 0;
    position: relative;
    margin-bottom: 10px;
    /* box-shadow: 0 0 15px rgb(0 0 0 / 50%); */
}

.app__navbar-logo {
    /* margin-left: 20px; */
    /* margin-top: 20px; */
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
}

.app__navbar-actions {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.app__navbar-smallscreen{
   
}
.app__navbar-search {
    cursor: pointer;
    margin: .5rem;
    position: relative;
    overflow: hidden;
     /* transform: rotateY(180deg); */
}

.true_event svg {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
}

.true_event input {
    position: relative;
    animation: mymove 5s infinite;
    background: #989898;
}

@keyframes mymove {
    from {
        left: 0px;
    }
    to {
        left: 200px;
    }
}

.app__navbar-search input {
    position: inherit;
    border: none;
    outline: none;
    padding: 6px 12px;
    width: 100%;
    color: #fff;
}

.app__navbar-logo img {
    width: 100%;
    max-width: 226px;
}

.app__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    padding: 0px;
}

.app__navbar-links li {
    margin: 0px 7px;
    cursor: pointer;
}

.app__navbar-links li a { 
    font-family: "Arvo",Arial,Verdana;
    font-size: 15px;
    color: black;
    line-height: 30px;
    font-weight: 400;
}

.app__navbar-links li a:hover {
    color: #FF7500;
    border-bottom: 2px solid #ff7400;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #e9e9e9;
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 99999;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: black;
    cursor: pointer;
    margin: 20px 0 0 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
    padding: 0;
    margin-top: 15px;
}

.app__navbar-smallscreen_links li {
    cursor: pointer;
    justify-content: center;
    border: 1px solid #000000;
    width: calc(50% - 6%);
    float: left;
    display: inline-block;
    text-align: center;
}

.app__navbar-smallscreen_links li:nth-child(odd) {
    margin: 5px 2% 5px 4%;
}

.app__navbar-smallscreen_links li:nth-child(even) {
    margin: 5px 4% 5px 2%;
}

.app__navbar-smallscreen_links li a {
    font-family: "Arvo", Arial, Verdana;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    color: black;
    line-height: 30px;
    font-weight: 400;
}

.app__navbar-smallscreen_links li:hover {
    color: #FF7500;
}


@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }
    .app__navbar-smallscreen {
        display: flex;
        /* display: flex;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%); */
    }
    /* .app__navbar-logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    } */
    /* .app__navbar {
        justify-content: flex-end;
    } */
}
@media screen and (max-width: 767px) {
    p.cookie_text {
        max-width: 100% !important;
        font-size: 14px;
        font-weight: 500 !important;
    }
}
@media screen and (max-width: 650px) {
    .app-navbar {
        padding: 1rem;
    }
    .app__navbar-login {
        display: none;
    }
}

.false_event svg {
    float: right;
    animation: mymove 5s infinite;
    font-size: 22px;
    transform: rotateY(180deg);
    z-index: 9999;
}

@media screen and (min-width:768px) and (max-width:1199px){
    .app__navbar-links li a{
        font-size: 13px;
    }
    .app__navbar-links li{
        margin: 0px 3px;
    }
}
@media screen and (max-width:1150px){
    .app__navbar-search{
        margin: 0;
    }
}
@media screen and (min-width:1151px) {
    /* .app__navbar-search{
        position: absolute;
       top: 7px;
        right: 0;
    } */
    /* .app__navbar-search{
        max-width: 300px;
    } */
    /* .app__navbar-links{
        margin-right: 30px;
    } */
    .app__navbar{
        box-shadow: none;
        top: 9px !important;
        /* right: 15px; */
    }
    .app__navbar-search{
        max-width: 200px;
    margin-right: 0;
    margin-left: auto;
    }
    
}


