.movieTitle {
  font-family: "Arvo", Arial, Verdana;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 4px;
  font-weight: 700;
}

.searchHeading {
  text-transform: none;
  font-family: "Arvo", Arial, Verdana;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  color: #ff7500;
}

.searchString {
  text-transform: none;
  font-family: "Arvo", Arial, Verdana;
  font-size: 39px;
  line-height: 50px;
  font-weight: 700;
  color: black;
}

.startdate {
  font-family: "Arvo", Arial, Verdana;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  color: rgb(255, 117, 0);
  margin-bottom: 0;
}

.searchResult {
  text-transform: none;
  font-family: "Arvo", Arial, Verdana;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: black;
}

.mehrBtn {
  background: rgb(140, 141, 141);
  color: rgb(255, 255, 255);
  border: none;
}

.form-input-button {
  border: 1px solid #000000;
  height: 40px;
  text-transform: uppercase;
  font-family: "Arvo", Arial, Verdana;
  font-size: 12px;
  line-height: 36px;
  font-weight: 700;
  color: black;
  padding: 0 20px 0 20px;
  background-color: #e9e9e9;
  cursor: pointer;
  pointer-events: auto;
  text-align: center;
  outline: none;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.movieDiv {
  transition: 0.3s;
  max-width: 1000px;
}

.movieDiv:hover {
  box-shadow: 0 0 15px 1px #80808045;
}
