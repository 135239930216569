.slick-prev,
.slick-next {
    transform: none;
    width: 40px;
}

/* .slick-prev {
    left: 20px;
}
.slick-next {
    right: 20px;
} */
/* .slick-next:before, .slick-prev:before {
    font-family: monospace;
    font-size: 26px;
    line-height: 1;
    opacity: 1;
    color: #000;
} */
.slick-next:before,
.slick-prev:before {
    content: none;
}

/* .slick-next:before {
    content: '>';
}
.slick-prev:before {
    content: '<';
} */
.slick-next,
.slick-prev,
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background-repeat: no-repeat;
}

.slick-next {
    /* background-image: url(https://www.schulkino.at/typo3conf/ext/school_cinema/Resources/Public/css/img/btn_next1.png); */
    background-image: url(../media/arrows/homeArrow.svg);
    width: 35px;
    height: 35px;
    border: 2px solid #111111;
    background-color: #E6E5E6;
    background-position: center center;
    background-size: 6px;
}

.slick-prev {
    background-image: url(../media/arrows/homeArrow.svg);
    transform: rotate(180deg);
    width: 35px;
    height: 35px;
    background-color: #E6E5E6;
    background-position: center center;
    border: 2px solid #111111;
    background-size: 6px;
    /* background-image: url(https://www.schulkino.at/typo3conf/ext/school_cinema/Resources/Public/css/img/btn_prev1.png); */
}

.main_slider_part {
    position: relative;
}

.left_slider_section,
.right_slider_section {
    /* position: relative; */
    height: 100%;
}

button.slick-arrow.slick-prev {
    position: absolute;
    left: 10px;
    z-index: 999;
}

button.slick-arrow.slick-next {
    position: absolute;
    right: 10px;
    z-index: 999;
}

.slick-next,
.slick-prev {
    /* height: 30px;
    width: 30px; */
    /* width: 100%; */
    /* height: 100%; */
    /* border: 2px solid #000; */
    z-index: 9;
    /* background: #fff !important; */
}

.slick-prev:hover,
.slick-prev:focus {
    /* background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_prev2.png); */
    background-image: url(../media/arrows/homeArrow.svg);
    transform: rotate(180deg);
    width: 35px;
    height: 35px;
    background-color: #E6E5E6;
    background-position: center center;
    border: 2px solid #111111;
    background-size: 6px;
}

.slick-next:hover,
.slick-next:focus {
    /* background-image: url(https://www.schulkino.at//typo3conf/ext/school_cinema/Resources/Public/css/img/btn_next2.png); */
    background-image: url(../media/arrows/homeArrow.svg);
    width: 35px;
    height: 35px;
    border: 2px solid #111111;
    background-color: #E6E5E6;
    background-position: center center;
    background-size: 6px;
}

@media only screen and (max-width: 767px) {

    .slick-next,
    .slick-prev,
    .slick-next:hover,
    .slick-prev:hover {
        height: 25px;
        width: 25px;
        /* background-size: cover; */
    }

    .slick-next:before,
    .slick-prev:before {
        font-size: 20px;
    }

    .row {
        margin: 0px;
    }

    .slick-next,
    .slick-prev {
        z-index: 4;
    }
}

button.age_button {
    height: 54px;
    width: 235px;
    font-family: Arvo;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    /* background: transparent; */
    border-width: 1px;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        /* max-width: 1280px; */
        max-width: 1380px;
    }
}

.home_page {
    margin: 0 auto;
}

.home_page .row .custom_col {
    padding: 0px;
    padding: 0px 12.5px;
}

.modal-content {
    background: #e9e9e9;
    border-radius: 2px;
}

.modal-body {
    padding: 0px;
}

button.btn-close {
    position: absolute;
    z-index: 9;
    right: 2%;
    top: 10%;
}

.modal-header {
    padding: 0px;
}

button.btn-close {
    background: #fff;
    opacity: 1;
    border-radius: 0px;
}

button.btn-close::before {
    content: "⨉";
    position: absolute;
    font-family: cursive;
    font-size: 23px;
    bottom: 0%;
    right: 20%;
    font-weight: 100;
    color: #323232;
}

.app_nabbar_margin {
    margin: 0 5px;
}

.top_bar {
    background: #E6E5E6;
    padding: 10px 0px;
}

p.cookie_text {
    margin-bottom: 0px;
    float: left;
    font-weight: 600;
    max-width: 90%;
}

a.cookie_link {
    color: #ff7400;
    font-weight: 600;
}

button.cookie_btn {
    background: #ff7400;
    border: none;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
}

/* .modal-dialog.modal-xl {
    top: 50%;
} */
/* .modal-dialog.modal-xl {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 1500px;
} */
/* .modal-lg, .modal-xl {
    --bs-modal-width: 1500px;
} */
.custom-item {
    /* // background-color: #fff;
    // border: 1px solid rgba(0,0,0,0.1); */
    min-width: 40px;
    min-height: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    cursor: pointer;
    background-color: #e9e9e9;
    color: #363636;
    border: none;
    font-weight: 500;
}

.custom-item--active {
    background-color: #ff7400;
    /* // border-radius: 50px; */
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.custom-item--disable {
    opacity: 0.5;
}

.custom-root ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.custom-go-item {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
    width: 150px;
}

.custom-go-item input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 40px;
    width: 100%;
}

.custom-progress-bar {
    background-color: #9e75ff;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
    height: 3px;
    margin: 1rem 0;
    max-width: 100%;
    transition: width 0.2s ease;
}

ul#rpb-pagination {
    background: #e9e9e9;
    margin: 0 auto;
    width: 100%;
    max-width: fit-content;
    margin-top: 50px;
}

@media (max-width: 441px) {
    .slick-next {
        right: 0px;
    }

    .slick-prev {
        left: 0px;
    }
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    left: -14px !important;
}